import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Card, Form, Input, Select, DatePicker } from 'antd';

import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import { useFormDetail } from '../../../util/dataProvider';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {NotificationContainer, NotificationManager} from "react-notifications";

const FormItem = Form.Item;
const { Option } = Select;
const { MonthPicker, RangePicker } = DatePicker;

const StudentStockForm = () => {
	const [form] = Form.useForm();
	const {
		loading,
		createSubject,
		success,
		setSuccess,
		getClassList,
		classList,
		createCat,
		getSubjectListByClass,
		subjectListByClass,
		stockIssue,
		getStationeryList,
		stationeryList,
		studentListStock,
		assignedBookList,
		assignedNoteBookList,
		assignedStationeryList
	} = useFormDetail();

	useEffect(() => {
		getClassList();
		getStationeryList();
	}, []);

	const onFinish = (values) => {
		//console.log('Received values of form: ', values);
		if(!values.student){
             alert('Please select a student');
			 return;
		}
		if(assignedBookList.length==0){
			alert('Please select assigned class');
			return;
		}
		stockIssue(values);
	};

	useEffect (() => {
    
		if(success){
		 form.resetFields();
		 NotificationManager.success('Stock added Successfully', 'Success', 2000);
		 setSuccess(false);
		}
	   }, [success]);

	const handleChangeClass = (value) => {
		
		getSubjectListByClass({ classId: value });
	};

	
	return (
		
		<Form
			form={form}
			autoComplete="off"
			//initialValues={{ remember: true }}
			name="basic"
			onFinish={onFinish}
			//onFinishFailed={this.onFinishFailed}
			layout="inline"
			
		>
		
				<Card className="gx-card" title="Add Student Stock" style={{width:'100%'}} >
					<Row>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem
								 name="classId"
								 rules={[
									{
										required: true,
										message: "Please choose class."
									}
								]}
								label={<span>Class &nbsp;</span>}
							>
								<Select
									showSearch
									//style={{ width: 250 }}
									placeholder="Select a class"
									optionFilterProp="children"
									onChange={handleChangeClass}
									//onFocus={handleFocus}
									//onBlur={handleBlur}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{classList &&
										classList.map((item) => (
											<Option key={item.id} value={item.id}>
												{item.classname}
											</Option>
										))}
								</Select>
							</FormItem>
						</Col>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem
								 name="student" 
								label={<span>Student &nbsp;</span>}
								rules={[
									{
										required: false,
										message: "Please choose student."
									}
								]}
							>
								<Select
									showSearch
									//style={{ width: 250 }}
									name="student"
									placeholder="Select a student"
									optionFilterProp="children"
									required={true}
									//onFocus={handleFocus}
									//onBlur={handleBlur}
									filterOption={(input, option) =>
										option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{studentListStock && studentListStock.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.name}
										</Option>
									))}
								</Select>
							</FormItem>
						</Col>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
						<FormItem name="paid_status" label={<span> Status &nbsp;</span>}
							rules={[
								{
									required: false,
									message: "Please select status."
								}
							]}>
								<Select placeholder="Fees Status" >
									<Option value="pending">Pending</Option>
									<Option value="paid">Paid</Option>
								</Select>
							</FormItem>
							</Col>

					</Row>
				</Card>

				{/* <Card className="gx-card" title="Stutdent info" style={{width:'100%'}}>
					<Row>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem name="student_name" label={<span>Student Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter student name."
								}
							]}>
								<Input placeholder="Student name" />
							</FormItem>
							<FormItem name="admission_number" label={<span>Admission. No &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter admission no."
								}
							]}>
								<Input placeholder="Addmission No" />
							</FormItem>
							<FormItem name="dob" label={<span>Date of Birth &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter date of birth."
								}
							]}>
								<DatePicker className=" gx-w-100" />
							</FormItem>
							<FormItem name="father_name" label={<span>Father's Name &nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter father name."
								}
							]}>
								<Input placeholder="" />
							</FormItem>
						</Col>
						<Col  xs={24} sm={24} md={12} lg={12} xl={12}>
							<FormItem name="academicyear" label={<span>Academic Year &nbsp;</span>}
							rules={[
								{
									required: true,
									message:	"Please select academic year."
								}
							]}>
								<Select placeholder="Year">
									<Option value="2022">2022-2023</Option>
									<Option value="2023">2023-2024</Option>
								</Select>
							</FormItem>
							<FormItem name="section_name" label={<span>Section&nbsp;</span>}
							rules={[
								{
									required: true,
									message: "Please enter section."
								}
							]}>
								<Input placeholder="Section" />
							</FormItem>
							<FormItem name="mobile_number" label={<span>Mobile no. &nbsp;</span>}>
								<Input placeholder="" 
								//onChange={onChangeMobile}
								 />
							</FormItem>

							<FormItem name="paid_status" label={<span>Status &nbsp;</span>}
							rules={[
								{
									required: false,
									message: "Please select status."
								}
							]}>
								<Select placeholder="Status" >
									<Option value="pending">Pending</Option>
									<Option value="paid">Paid</Option>
								</Select>
							</FormItem>
						</Col>
					</Row>	
				</Card> */}
		
				{/* Books formlist */}
				<Card className="gx-card" title="Books entry" style={{width:'100%'}}>
				
				<Row key={1}>
							<Col span={3}>
								<p style={{fontWeight:'bold'}}>S.NO</p>
							</Col>
							<Col span={7}>
								<p style={{fontWeight:'bold'}}>Subject Name</p>
							</Col>
							<Col span={7}>
								<p style={{fontWeight:'bold'}}>Book Name</p>
							</Col>
							<Col span={5}>
								<p style={{fontWeight:'bold'}}>Quantity</p>
							</Col>
							</Row>
					
					{assignedBookList && assignedBookList.map((item, index) => {
						return(
						<Row key={index}>
							<Col span={3}>
							    <p>{index+1}</p>
							</Col>
							  <Col span={7}>
							      <p>{item.subject_name}</p>
							</Col>
								<Col span={7}>
								<p>{item.book_name}</p>
								</Col>
								<Col span={5}>
								<FormItem  name={['bookqty', [index]]} label={false}
								{...item}
								//value={item.qty}
								initialValue={item.qty}
								defaultValue={item.qty}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="quantity" value={item.qty} />
												</FormItem>
										</Col>
										</Row>
										)})}

				
						{/* <Form.List name="books" >
							{(fields, { add, remove }) => {
								return (
									<div>
									<Row>
										{fields.map((field, index) => (
											<>
											<Col span={10}>
												<Form.Item
													label={<span>Subject &nbsp;</span>}
													{...field}
													name={[field.name, 'subjectId']}
													rules={[
														{
															required: true,
															message: "Please select subject"
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select subject"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{subjectListByClass &&
														subjectListByClass.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.subjectname}
															</Option>
														))}
												</Select>
												</Form.Item>
											
											</Col>
											<Col span={10}>
											
												<FormItem {...field} name={[field.name, 'qty']} label={<span>Quantity &nbsp;</span>}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="qty" />
												</FormItem>
											</Col>
											<Col span={4}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{ margin: '0 8px' }}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '20%' }}
											>
												<PlusOutlined /> Issue Books
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List> */}
						
					
				</Card>

				<Card className="gx-card" title="Note Books entry" style={{width:'100%'}}>
				<Row key={1}>
							<Col span={3}>
								<p style={{fontWeight:'bold'}}>S.NO</p>
							</Col>
							<Col span={7}>
								<p style={{fontWeight:'bold'}}>Subject Name</p>
							</Col>
							<Col span={7}>
								<p style={{fontWeight:'bold'}}>Note Book</p>
							</Col>
							<Col span={5}>
								<p style={{fontWeight:'bold'}}>Quantity</p>
							</Col>
							</Row>
			
					{assignedNoteBookList && assignedNoteBookList.map((itemData, i) => (
						<Row key={i}>
							<Col span={3}>
							    <p>{i+1}</p>
							</Col>
							  <Col span={7}>
							      <p>{itemData.subject_name}</p>
							</Col>
								<Col span={7}>
								<p>{itemData.book_name}</p>
								</Col>
								<Col span={5}>
	
								<FormItem  name={['noteBookqty', [i]]} label={false}
								{...itemData}
								//value={item.qty}
								initialValue={itemData.qty}
								defaultValue={itemData.qty}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input name={'noteQty[]'} placeholder="quantity" value={itemData.qty} />
												</FormItem>
										</Col>
										</Row>
										))}
					
				</Card>
			
				{/* Note formlist */}
				{/* <Card className="gx-card" title="Note book entry" style={{width:'100%'}}>
						<Form.List name="notebooks">
							{(fields, { add, remove }) => {
								return (
									<div>
									<Row>
										{fields.map((field, index) => (
											<>
											<Col span={10}>
												<Form.Item
													label={<span>Subject &nbsp;</span>}
													{...field}
													name={[field.name, 'subjectId']}
													rules={[
														{
															required: true,
															message: "Please select subject"
														}
													]}
													
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select subject"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{subjectListByClass &&
														subjectListByClass.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.subjectname}
															</Option>
														))}
												</Select>
												
												</Form.Item>
											
											</Col>
											<Col span={10}>
												<FormItem {...field} name={[field.name, 'qty']} label={<span>Quantity &nbsp;</span>}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="qty" />
												</FormItem>
											</Col>
											<Col span={4}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{ margin: '0 8px' }}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
										<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '25%' }}
											>
												<PlusOutlined /> Issue note book
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List>
				</Card>	 */}

				{/* Stationary formlist */}

				<Card className="gx-card" title="Stationery entry" style={{width:'100%'}}>
				<Row key={1}>
							<Col span={3}>
								<p style={{fontWeight:'bold'}}>S.NO</p>
							</Col>
							
							<Col span={7}>
								<p style={{fontWeight:'bold'}}>Stationery Name</p>
							</Col>
							<Col span={5}>
								<p style={{fontWeight:'bold'}}>Quantity</p>
							</Col>
							</Row>
			
					{assignedStationeryList && assignedStationeryList.map((item, index) => (
						<Row key={index}>
							<Col span={3}>
							    <p>{index+1}</p>
							</Col>
							 
								<Col span={7}>
								<p>{item.book_name}</p>
								</Col>
								<Col span={5}>
								
								<FormItem  name={['stationery', [index]]} label={false}
								{...item}
								//value={item.qty}
								initialValue={item.qty}
								defaultValue={item.qty}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="quantity" value={item.qty} />
												</FormItem>
										</Col>
										</Row>
										))}
					
				</Card>

				{/* <Card className="gx-card" title="Stationary entry" style={{width:'100%'}}>
						<Form.List name="stationary">
							{(fields, { add, remove }) => {
								return (
									<div>
										<Row>
										{fields.map((field, index) => (
											<>
											<Col span={10}>
												<Form.Item
													label={<span>Stationary name &nbsp;</span>}
													{...field}
													name={[field.name, 'name']}
													rules={[
														{
															required: true,
															message: "Please enter book name."
														}
													]}
												>
												<Select
													showSearch
													allowClear
													style={{ width: 250 }}
													placeholder="Select a stationary"
													optionFilterProp="children"
													//onChange={handleChangeClass}
													filterOption={(input, option) =>
														option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
												>
													{stationeryList &&
														stationeryList.map((item) => (
															<Option key={item.id} value={item.id}>
																{item.name}
															</Option>
														))}
												</Select>
												
												</Form.Item>
											
											</Col>
											<Col span={10}>
											
												<FormItem {...field} name={[field.name, 'qty']} label={<span>Quantity &nbsp;</span>}
												rules={[
													{
														required: true,
														message: "Please enter quantity."
													}
												]}>
													<Input placeholder="qty" />
												</FormItem>
											</Col>
											<Col span={4}>
												{fields.length > 1 ? (
													<MinusCircleOutlined
														className="dynamic-delete-button"
														style={{ margin: '0 8px' }}
														onClick={() => {
															remove(field.name);
														}}
													/>
												) : null}
											</Col>
											</>
										))}
										
									</Row>
									<Form.Item>
											<Button
												type="dashed"
												onClick={() => {
													add();
												}}
												style={{ width: '25%' }}
											>
												<PlusOutlined /> Issue stationary
											</Button>
										</Form.Item>
										</div>
								);
							}}
						</Form.List>
				</Card>		 */}
			
				<Card className="gx-card"  style={{width:'100%'}}>
						<Button type="primary" htmlType="submit" className="gx-mb-3 gx-btn-blue"> Submit </Button>
				</Card>
				<NotificationContainer/>
	</Form>
	);
};

export default StudentStockForm;

import React, { useState, useEffect, useContext, createContext } from "react";
//import { useAuth } from '../util/use-auth';
import { getMethodCall, getDeleteCall, UpdateDataCall,getMethodCall2ndDB } from "./apiService";
//import Constants from 'expo-constants';
//import AsyncStorage from '@react-native-async-storage/async-storage';
//import { NavigationContext } from '@react-navigation/native';
export const formProvider = createContext();
//import { useDetail } from '../context/authProvider';

// Provider component that wraps your app and makes table object ..
// ... available to any child component that calls useTable().

export function FormProvideDetail({ children, ...props }) {
  const detail = useProvideDetail(props);
  return (
    <formProvider.Provider value={detail}>{children}</formProvider.Provider>
  );
}

// Hook for child components to get the table object ...
// ... and re-render when it changes.

export const useFormDetail = () => {
  return useContext(formProvider);
};

// Provider hook that creates table object and handles state

function useProvideDetail(props) {
  //const navigation = React.useContext(NavigationContext);
  const [loading, setLoading] = useState(false);
  const [navi, setNavi] = useState({});
  const [success, setSuccess] = useState(false);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectListByClass, setSubjectListByClass] = useState([]);
  const [bookStockList, setBookStockList] = useState([]);
  const [stationeryStockList, setStationeryStockList] = useState([]);

  const [bookList, setBookList] = useState([]);
  const [noteBookList, setNoteBookList] = useState([]);
  const [stockIssueList, setStockIssueList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [studentListStock, setStudentListStock] = useState([]);
  const [assignedBookList, setAssignedBookList] = useState([]);
  const [assignedNoteBookList, setAssignedNoteBookList] = useState([]);
  const [assignedStationeryList, setAssignedStationeryList] = useState([]);
  const [visitorList, setVisitorList] = useState([]);

  const [stationeryList, setStationeryList] = useState([]);
  const [classWiseList, setClassWiseList] = useState([]);
  const [bookListByClass, setBookListByClass] = useState([]);

  const [bookListAll, setBookListAll] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);

  //setEditClass
  const [editClass, setEditClass] = useState(false);
  const [editSubject, setEditSubject] = useState(false);
  const [editBook, setEditBook] = useState(false);
  const [editStationery, setEditStationery] = useState(false);
  const [editStocklist, setEditStocklist] = useState(false);
  const [stockrecord, setStockrecord] = useState([]); 
  const [resource, setResource] = useState({ email: "", password: "" });
  
  const [resultList, setResultList] = useState([]); 


  const getDasbhoardData = (data) => {
    setLoading(true);
    let resource = "stockmgt/dasboard";
    getMethodCall(resource, data, successCallbackDasboard, errorCallback);
  };
  const successCallbackDasboard = (response) => {
    setLoading(false);
    if (response.errorCode == 0) {
      setDashboardData(response.result);
    }
  };

  const createClass = (data) => {
    setLoading(true);
    let resource = "stockmgt/createclass";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const successCallback = (response) => {
    console.log(response);
    setSuccess(true);
    setLoading(false);
    return response;
  };
  const getClassList = (data) => {
    setLoading(true);
    let resource = "stockmgt/classlist";
    getMethodCall(resource, data, successCallbackClassList, errorCallback);
  };
  const successCallbackClassList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setClassList(response.result);
    }
  };

  const getDeleteClass = (id) => {
    setLoading(true);
    let resource = "stockmgt/deleteclass";
    getDeleteCall(resource, id, successCallbackDelete, errorCallback);
  };
  const successCallbackDelete = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getClassList();
      setSuccess("Class deleted successfully !!");
    }
  };
  const createSubject = (data) => {
    setLoading(true);
    let resource = "stockmgt/createsubject";
    getMethodCall(resource, data, successCallback, errorCallback);
  };
  const getSubjectList = (data) => {
    setLoading(true);
    let resource = "stockmgt/subjectlist";
    getMethodCall(resource, data, successCallbackSubjectList, errorCallback);
  };

  const successCallbackSubjectList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setSubjectList(response.result);
    } else {
      setSubjectList([]);
    }
  };

  const getSubjectListByClass = (data) => {
    setLoading(true);
    let resource = "stockmgt/subjectlistbyclass";
    getMethodCall(
      resource,
      data,
      successCallbackSubjectListByclass,
      errorCallback
    );
  };

  const getBookListAll = (data) => {
    setLoading(true);
    let resource = "stockmgt/booklistall";
    getMethodCall(resource, data, successCallbackBooklistAll, errorCallback);
  };

  const successCallbackBooklistAll = (response) => {
    console.log("listAll", response);
    setLoading(false);
    if (response.errorCode == 0) {
      setBookListAll(response.result);
    } else {
      setBookListAll([]);
    }
  };

  const getBookListByClass = (data) => {
    setLoading(true);
    let resource = "stockmgt/booklistbyclass";
    getMethodCall(
      resource,
      data,
      successCallbackBookListByclass,
      errorCallback
    );
  };

  const successCallbackBookListByclass = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setBookListByClass(response.result);
    } else {
      setBookListByClass([]);
    }
  };

  const successCallbackSubjectListByclass = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setSubjectListByClass(response.result);
      setStudentListStock(response.studentList);
      setAssignedBookList(response.assignedBookList);
      setAssignedNoteBookList(response.assignedNoteBookList);
      setAssignedStationeryList(response.assignedStationeryList);
    } else {
      setSubjectListByClass([]);
      setStudentListStock([]);
    }
  };

  const createStationeryCat = (data) => {
    setLoading(true);
    let resource = "stockmgt/createstationerycat";
    getMethodCall(resource, data, successCallback, errorCallback);
  };
  const createCat = (data) => {
    setLoading(true);
    let resource = "stockmgt/createcat";
    getMethodCall(resource, data, successCallback, errorCallback);
  };
  const createBook = (data) => {
    setLoading(true);
    let resource = "stockmgt/createbook";
    getMethodCall(resource, data, successCallback, errorCallback);
  };
  const updateStatus = (data) => {
    setLoading(true);
    let resource = "StatusUpdate.php";
    getMethodCall2ndDB(resource, data, successCallback, errorCallback);
  };
  // createStudent
  const createStudent = (data) => {
    setLoading(true);
    let resource = "stockmgt/createstudent";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const getBookList = (data) => {
    setLoading(true);
    let resource = "stockmgt/booklist";
    getMethodCall(resource, data, successCallbackBookList, errorCallback);
  };

  const getBookNoteList = (data) => {
    setLoading(true);
    let resource = "stockmgt/booknotebooklist";
    getMethodCall(resource, data, successCallbackBookList, errorCallback);
  };
  const getNoteBookList = (data) => {
    setLoading(true);
    let resource = "stockmgt/notebooklist";
    getMethodCall(resource, data, successCallbackNoteBookList, errorCallback);
  };
  const getBookStock = (data) => {
    setLoading(true);
    let resource = "stockmgt/stockreport";
    getMethodCall(resource, data, successCallbackBookReport, errorCallback);
  };

  const successCallbackBookReport = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setBookStockList(response.result);
    }
  };
  const getStationeryStock = (data) => {
    setLoading(true);
    let resource = "stockmgt/stationeryreport";
    getMethodCall(
      resource,
      data,
      successCallbackStationeryReport,
      errorCallback
    );
  };
  const successCallbackStationeryReport = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setStationeryStockList(response.result);
    }
  };

  const successCallbackNoteBookList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setNoteBookList(response.result);
    } else {
      setNoteBookList([]);
    }
  };
  const getStationeryList = (data) => {
    setLoading(true);
    let resource = "stockmgt/stationerylist";
    getMethodCall(resource, data, successCallbackStationeryList, errorCallback);
  };

  const getDeleteCat = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletecat";
    getDeleteCall(resource, id, successCallbackDeleteCat, errorCallback);
  };

  const getDeleteBook = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletebook";
    getDeleteCall(resource, id, successCallbackDeleteBook, errorCallback);
  };

  const successCallbackDeleteBook = (data) => {
    if (data.errorCode == 0) {
      setSuccess("Book deleted successfully !!");
    }
  };

  const getDeleteSubject = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletesubject";
    getDeleteCall(resource, id, successCallbackDeletesubject, errorCallback);
  };

  const successCallbackDeletesubject = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getSubjectList();
      setSuccess("Subject deleted successfully !!");
    }
  };

  const getDeleteStationery = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletecat";
    getDeleteCall(resource, id, successCallbackDeleteStationery, errorCallback);
  };
  const successCallbackDeleteStationery = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getStationeryList();
      setSuccess("Stationery deleted successfully !!");
    }
  };
  const successCallbackDeleteCat = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getBookList({ type: "book" });
      getNoteBookList({ type: "notebook" });
      setSuccess("Book deleted successfully !!");
    }
  };

  const successCallbackBookList = (response) => {
    console.log('notebooklist',response);
    setLoading(false);
    if (response.errorCode == 0) {
      setBookList(response.result);
    } else {
      setBookList([]);
    }
  };
  const successCallbackStationeryList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setStationeryList(response.result);
    }
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
    return error;
  };

  const stockIssue = (data) => {
    setLoading(true);
    let resource = "stockmgt/stockissue";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const getStockIssueList = (data) => {
    setLoading(true);
    let resource = "stockmgt/stockissuelist";
    getMethodCall(resource, data, successCallbackStockIssueList, errorCallback);
  };
  const successCallbackStockIssueList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setStockIssueList(response.result);
    }
  };

  const updateClass = (data, id) => {
    setLoading(true);
    data.id = id;
    let resource = "stockmgt/updateclass";
    getMethodCall(resource, data, successCallbackUpdateClass, errorCallback);
  };
  const updateStudent = (data) => {
    setLoading(true);
   // data.id = id;
    let resource = "stockmgt/updatestudent";
    getMethodCall(resource, data, successCallbackUpdatestudent, errorCallback);
  };
  const successCallbackUpdatestudent = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getStudentList()
      setSuccess("Student updated successfully !!");
    }
  };

  const successCallbackUpdateClass = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getClassList();
      setSuccess("Class updated successfully !!");
    }
  };
  const editCat = (data, id) => {
    setLoading(true);
    data.id = id;
    let resource = "stockmgt/editcat";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const updateStationeryCat = (data, id) => {
    setLoading(true);
    data.id = id;
    let resource = "stockmgt/updatestationerycat";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const updateSubject = (data, id) => {
    setLoading(true);
    data.id = id;
    let resource = "stockmgt/updatesubject";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const updateBook = (data) => {
    setLoading(true);
    //data.id = id;
    let resource = "stockmgt/updatebook";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const getStudentList = (data) => {
    setLoading(true);
    let resource = "stockmgt/studentlist";
    getMethodCall(resource, data, successCallbackStudentList, errorCallback);
  };
  const successCallbackStudentList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setStudentList(response.result);
    } else {
      setStudentList([]);
    }
  };

  const getDeleteStudent = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletestudent";
    getDeleteCall(resource, id, successCallbackDeletestudent, errorCallback);
  };

  const successCallbackDeletestudent = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setSuccess("Student deleted successfully !!");
    }
  };

  const Createclasswise = (data) => {
    setLoading(true);
    let resource = "stockmgt/createclasswise";
    getMethodCall(resource, data, successCallback, errorCallback);
  };

  const successCallbackClassWise = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
    }
  };

  const getClassWiseList = (data) => {
    setLoading(true);
    let resource = "stockmgt/classwiseissuelist";
    getMethodCall(resource, data, successCallbackClassWiseList, errorCallback);
  };

  const successCallbackClassWiseList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setClassWiseList(response.result);
    } else {
      setClassWiseList([]);
    }
  };


  const getDeleteAssign = (id) => {
    setLoading(true);
    let resource = "stockmgt/deleteassign";
    getDeleteCall(resource, id, successCallbackDeleteAssign, errorCallback);
  };

  const successCallbackDeleteAssign = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getClassWiseList();
      setSuccess("Class assigned deleted successfully !!");
    }
  };

  const getVisitorList = (data) => {
    setLoading(true);
    let resource = "stockmgt/visitorslist";
    getMethodCall(resource, data, successCallbackVisitorList, errorCallback);
  };
  const successCallbackVisitorList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setVisitorList(response.result);
    } else {
      setVisitorList([]);
    }
  };


  const getResultList = (data) => {
    setLoading(true);
    let resource = "regiteredUser.php";
    getMethodCall2ndDB(resource, data, successCallbackResultList, errorCallback);
  };

  const successCallbackResultList = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      setResultList(response.result);
    } else {
      setResultList([]);
    }
  };


 
  const getDeleteVisitor = (id) => {
    setLoading(true);
    let resource = "stockmgt/deletevisitor";
    getDeleteCall(resource, id, successCallbackDeleteVisitor, errorCallback);
  };
  const successCallbackDeleteVisitor = (response) => {
    console.log(response);
    setLoading(false);
    if (response.errorCode == 0) {
      getVisitorList();
      setSuccess("Deleted successfully !!");
    }
  };


  useEffect(() => {
    // alert('useEffect');
    if(editStocklist){
      getStockData(editStocklist);
    }
  }, [editStocklist]);

  const getStockData = (data) => {
    setLoading(true);
    //let id = {id : data};
    let resource = "stockmgt/classwiseissuedata";
    getMethodCall(resource, {id : data}, successCallbackClassWiseData, errorCallback);
  };

  const successCallbackClassWiseData = (response) => {
    console.log('successCallbackClassWiseData',response);
    setLoading(false);
    if (response.errorCode == 0 && response.result.length > 0) {
      setStockrecord(response.result);
    } else {
      setStockrecord([]);
    }
  };

  const updateStockData = (data) => {
    setLoading(true);
    let resource = "stockmgt/updateclasswisedata";
    getMethodCall(resource, data, successCallbackUpdateClassWiseData, errorCallback);
  };

  const successCallbackUpdateClassWiseData = (response) => {
    console.log('successCallbackUpdateClassWiseData',response);
    setLoading(false);
    if (response.errorCode == 0 ) {
      getClassWiseList();
      setSuccess("Updated successfully !!");
    } 
  };

  /*public function actionUpdateclasswisedata()
	{
	    $req = file_get_contents("php://input");
        $_POST = json_decode($req,true);

		$response = null;
		$StockClass = StockClassWise::find()->where(['id'=>$_POST['id']])->one();
		if(!empty($StockExit))
		{
			$classid = $_POST['classId'] ? $_POST['classId'] : null ;
			$books = $_POST['books'] ? json_encode($_POST['books']) : null ;
			$notebooks = $_POST['notebooks'] ? json_encode($_POST['notebooks']) : null ;
			$stationery = $_POST['stationery'] ? json_encode($_POST['stationery']) : null ;
			
			$StockClass->classId = $classid;
			$StockClass->books = $books;
			$StockClass->notebooks = $notebooks;
			$StockClass->stationery = $stationery;
			$StockClass->save();

			$response = ['errorCode'=>0,'Message'=>'Class Added Successfully'];
		}else{
			$response = ['errorCode'=>1,'Message'=>'Update failed'];
		}
		
		return json_encode($response,JSON_PRETTY_PRINT);
	}
  */


  return {
    loading,
    createClass,
    success,
    setSuccess,
    getClassList,
    classList,
    getDeleteClass,
    getSubjectList,
    createSubject,
    subjectList,
    createCat,
    getBookList,
    bookList,
    subjectListByClass,
    setSubjectListByClass,
    getSubjectListByClass,
    getDeleteCat,
    createStationeryCat,
    stationeryList,
    getStationeryList,
    getDeleteStationery,
    stockIssue,
    stockIssueList,
    getStockIssueList,
    setEditClass,
    editClass,
    updateClass,
    setEditSubject,
    editSubject,
    setEditBook,
    editBook,
    editCat,
    setEditStationery,
    editStationery,
    updateStationeryCat,
    studentList,
    getStudentList,
    getDeleteSubject,
    getNoteBookList,
    noteBookList,
    bookStockList,
    setBookStockList,
    getBookStock,
    stationeryStockList,
    setStationeryStockList,
    getStationeryStock,
    Createclasswise,
    classWiseList,
    getClassWiseList,
    getDeleteAssign,
    updateSubject,
    createBook,
    getBookNoteList,
    getDeleteBook,
    updateBook,
    getBookListByClass,
    bookListByClass,
    createStudent,
    getBookListAll,
    bookListAll,
    getDeleteStudent,
    getDasbhoardData,
    dashboardData,
    studentListStock,
    assignedBookList,
    assignedNoteBookList,
    assignedStationeryList,
    visitorList,
    getVisitorList,
    getDeleteVisitor,
    setEditStocklist,
    editStocklist,
    stockrecord,
    setStockrecord,
    updateStockData,
    updateStudent,
    setResultList,
    resultList,
    getResultList,
    updateStatus
  };
}

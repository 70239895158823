import React, { useEffect, useState } from 'react';
import { useFormDetail } from '../../../util/dataProvider';
import { useHistory } from 'react-router-dom';
import {
	NotificationContainer,
	NotificationManager,
  } from "react-notifications";
import {
	Form,
	Input,
	Tooltip,
	Cascader,
	Select,
	Row,
	Col,
	Checkbox,
	Button,
	Card,
	AutoComplete,
	Popconfirm,
	Tag,
	Modal,
	Table
  } from "antd";
  ///7092713581
import { PDFExport } from '@progress/kendo-react-pdf';
const { Option } = Select;
const formItemLayout = {
	labelCol: {
	  xs: { span: 24 },
	  sm: { span: 8 },
	},
	wrapperCol: {
	  xs: { span: 24 },
	  sm: { span: 16 },
	},
  };
  const tailFormItemLayout = {
	wrapperCol: {
	  xs: {
		span: 24,
		offset: 0,
	  },
	  sm: {
		span: 16,
		offset: 8,
	  },
	},
  };
const VisitorsRecord = () => {
	const [form] = Form.useForm();
	const { 
        resultList,
        getResultList,loading, visitorList, getDeleteVisitor,updateStatus,success,setSuccess } = useFormDetail();
	const [ isModalVisible, setIsModalVisible ] = useState(false);
	const [ modalData, setModalData ] = useState(undefined);
	const [visible, setVisible] = useState(false);
	const pdfExportComponent = React.useRef(null);
	const [showMessage, setShowMessage] = useState('');
	const [selectedId, setSelectedId] = useState(null);
	let history = useHistory();

	useEffect(() => {
		getResultList();
		console.log('visitorList', visitorList);
	}, []);

	const onDelete = (id) => {
		getDeleteVisitor({ id: id });
		//setShowMessage("Record Deleted Successfully !!");
	};
	
	  function handleChange(value) {
		// getSubjectListByClass({classId:value})
		console.log(`selected ${value}`);
	  }
	
	  function handleBlur() {
		console.log("blur");
	  }
	
	  function handleFocus() {
		console.log("focus");
	  }
	  const handleOk = () => {
		// this.setState({
		//   ModalText: 'The modal will be closed after two seconds',
		//   confirmLoading: true,
		// });
		setTimeout(() => {
		  setVisible(false);
		}, 2000);
	  };
	  const handleCancel = () => {
		//editform.resetFields();
		//setEditBook(null);
		console.log("Clicked cancel button");
		setVisible(false);
		
	  };
	  const onFinish = (values) => {
		// alert(JSON.stringify(values));
		console.log("Received values of form: ", values);
		values.id = selectedId;
		updateStatus(values);
		setShowMessage("Record Updated Successfully");
	  };
	  useEffect(() => {
		if (success) {
		  //form.resetFields();
		  NotificationManager.success(showMessage, "Success");
	
		  setSuccess(false);
		  form.resetFields();
		  setTimeout(() => {
			getResultList();
			setVisible(false);
			//setEditvisible(false);
			NotificationManager.listNotify.forEach((n) =>
			  NotificationManager.remove({ id: n.id })
			);
			//history.push("/main/attempt-user");
			setShowMessage("");
		  }, 1000);
		}
	  }, [success]);
	const columns = [
		{ title: 'Name', dataIndex: 'name', key: 'name' },
		{ title: 'Father Name', dataIndex: 'fathername', key: 'fathername' },
		{ title: 'Phone', dataIndex: 'phone', key: 'phone' },
		{ title: 'Standard', dataIndex: 'standard', key: 'standard' },
		{ title: 'Gender', dataIndex: 'gender', key: 'gender' },
		{ title: 'Attempt', dataIndex: 'attempt', key: 'attempt' },
		{
			title: "Status",
			dataIndex: "id",
			key: "id",
			render: (text, record) => (
			  <span>
				<span className="gx-link">
				  <Tag
	  
					onClick={() => {
					  //getBookListByClass({ classId: record.classId,category:"notebook" });
					  setVisible(true);
					  setSelectedId(record.id);
					// setEditBook(record);
					//  form.setFieldsValue({
					//   name: record.name,
					//   numberofpage: record.numberofpage,
					//   classId: {key:record.classId,label:record.classname},
					//   subjectId: record.subjectId, 
					//   bookId: {key:record.bookId,label:record.name},  
					//   credit: record.credit,
	  
					//  });
					  //history.push("/main/edit-book");
					}}
					color={record.status === "pending" ?'yellow':record.status === "pass" ?'green':'red'}
					
				  >
					{record.status=== "pending" ?'Pending':record.status === "pass" ?'Pass':'Fail'}
				  </Tag>
				</span>
				{/* <span className="gx-link">
				  <Popconfirm
					title="Are you sure want to delete this record?"
					onConfirm={() => onDelete(record.id)}
					okText="Yes"
					cancelText="No"
					okButtonProps={{
					  type: "default",
					  style: { backgroundColor: "#A2A08A4D", color: "#000" },
					}}
					cancelButtonProps={{
					  type: "default",
					  style: { backgroundColor: "red", color: "#fff" },
					}}
				  >
					<Tag color="#f50">{record.status}</Tag>
				  </Popconfirm>
				</span> */}
			  </span>
			),
		  },
	];


	return (
		<Card
			title="Users List"
			/*extra={
				<Button
					type="primary"
					onClick={() => {
						//history.push('/main/stock-issue');
					}}
				>
					Issue Stock
				</Button>
			}*/
		>
			

		<Table
          className="gx-table-responsive"
          bordered
          columns={columns}
          dataSource={resultList}
          searchable
        />

<Modal
        title="Result Status"
        visible={visible}
        onOk={handleOk}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={false}
        width={"50%"}
      >
        <Form
          {...formItemLayout}
          form={form}
          name="bookRegister"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item
            name="status"
            label={<span>Select Status &nbsp;</span>}
            // rules={[{ required: true, message: 'Please Select Class', whitespace: true }]}
          >
            <Select
              showSearch
              //style={{width: 200}}
              name="status"
              placeholder="Select a Status"
              optionFilterProp="children"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
             <Option key={'pass'} value={'pass'}>
                  Pass
                </Option>
				<Option key={'fail'} value={'fail'}>
                  Fail
                </Option>
            </Select>
          </Form.Item>

          

          
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" loading={loading} htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

	  <NotificationContainer />
		</Card>
	);
};

export default VisitorsRecord;
